import {
    Dropdown as BulmaDropdown,
    DropdownItemProps,
    Navbar,
    NavbarBurgerProps,
    NavbarBrandProps,
    NavbarMenuProps,
    DropdownProps,
} from 'react-bulma-components';

// Because of bad support of TS in react-bulma-components,
// see https://github.com/couds/react-bulma-components/issues/182#issuecomment-489073112

export const Dropdown: React.ForwardRefExoticComponent<
    Omit<DropdownProps, 'onChange'> & { onChange: (value: any) => void }
> = BulmaDropdown as any;

export const DropdownItem: React.ForwardRefExoticComponent<
    DropdownItemProps & { renderAs: 'a' | 'form' | 'button'; href?: string } & React.RefAttributes<'div'>
> = BulmaDropdown.Item as any;

export const NavbarBurger: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<NavbarBurgerProps> & { renderAs: 'span' } & React.RefAttributes<'div'>
> = Navbar.Burger as any;

export const NavbarBrand: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<NavbarBrandProps> & { renderAs: 'div' } & React.RefAttributes<'div'>
> = Navbar.Brand as any;

export const NavbarMenu: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<NavbarMenuProps> & { renderAs: 'nav' } & React.RefAttributes<'div'>
> = Navbar.Menu as any;
