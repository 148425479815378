import { Locale } from './LocaleStateReducer';
import { LocaleActionType } from './LocaleActionType';
import { Action } from 'redux';

export interface LocaleAction extends Action {
    locale: Locale;
}

export function setLocale(locale: Locale): LocaleAction {
    return {
        type: LocaleActionType.SET,
        locale,
    };
}

export function updateLocale(locale: Locale): LocaleAction {
    return {
        type: LocaleActionType.UPDATE,
        locale,
    };
}
