import { LocaleActionType } from './LocaleActionType';
import { LocaleAction } from './LocaleActions';
import { Reducer } from 'redux';
import { setDayjsLocale } from '../../utils/DateAndTimeUtils';

export type Locale = 'fr' | 'en' | 'pt';

export const initialState: Locale = 'fr';
setDayjsLocale(initialState);

export const localeReducer: Reducer<Locale, LocaleAction> = (state = initialState, action: LocaleAction): Locale => {
    const isReduced: boolean = [LocaleActionType.UPDATE, LocaleActionType.SET].includes(action.type);
    if (isReduced) {
        setDayjsLocale(action.locale);
        return action.locale;
    }
    return state;
};
