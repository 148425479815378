import React, { ComponentClass, HTMLAttributes, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserView } from 'react-device-detect';
import { Dropdown } from 'react-bulma-components';
import { withTranslation } from '../../i18n';
import { createToggleAnimationsAction, createToggleContrastsAction, UIAction } from '../../modules/UI';
import { WithTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ActionCreator } from 'redux';
import { DropdownItem } from '../common/TypedBulmaComponents';
import { AppState } from '../../store/reducers';
import { updateLocale, LocaleAction } from '../../modules/locale';
import classNames from 'clsx';
import { Link } from '../common/A11y/Link';

interface StateProps {
    /**
     * Whether or not high contrasts is turned on
     */
    contrasts: boolean;

    /**
     * Whether or not animations are turned on
     */
    animations: boolean;
}

interface DispatchProps {
    toggleAnimations: ActionCreator<UIAction>;
    toggleContrasts: ActionCreator<UIAction>;
    updateLocale: ActionCreator<LocaleAction>;
}

interface OwnProps {
    withContactButton?: boolean;
    className?: string;
}

type LanguageAndAccessibilityDropdownsProps = OwnProps & StateProps & DispatchProps & WithTranslation;

function renderAccessibilityDropdown(
    LanguageAndAccessibilityDropdownsProps: LanguageAndAccessibilityDropdownsProps
): JSX.Element {
    const { t, contrasts, animations, toggleAnimations, toggleContrasts } = LanguageAndAccessibilityDropdownsProps;
    return (
        <ModalDropdown className="dropdown-acc is-right" color="light">
            <DropdownItem renderAs="button" value="Coucou" aria-expanded="false">
                {t('accessibility')}
            </DropdownItem>
            <DropdownItem value="" renderAs="form">
                <fieldset>
                    <legend>{t('contrasts')}</legend>
                    <div className="switch-button">
                        <p>
                            <input
                                type="radio"
                                id="contrasts-default"
                                name="contrasts"
                                value="contrasts-default"
                                checked={!contrasts}
                                onChange={(): void => {
                                    toggleContrasts();
                                }}
                            />
                            <label htmlFor="contrasts-default">{t('default')}</label>
                        </p>
                        <p>
                            <input
                                type="radio"
                                id="contrasts-high"
                                name="contrasts"
                                value="contrasts-high"
                                checked={contrasts}
                                onChange={(): void => {
                                    toggleContrasts();
                                }}
                            />
                            <label htmlFor="contrasts-high">{t('reinforced')}</label>
                        </p>
                    </div>
                </fieldset>
            </DropdownItem>
            <DropdownItem value="" renderAs="form">
                <fieldset>
                    <legend>{t('animations')}</legend>
                    <div className="switch-button">
                        <p>
                            <input
                                type="radio"
                                id="enable-anim"
                                name="anim"
                                value="enable-anim"
                                checked={animations}
                                onChange={(): void => {
                                    toggleAnimations();
                                }}
                            />
                            <label htmlFor="enable-anim">{t('enabled')}</label>
                        </p>
                        <p>
                            <input
                                type="radio"
                                id="disable-anim"
                                name="anim"
                                value="disable-anim"
                                checked={!animations}
                                onChange={(): void => {
                                    toggleAnimations();
                                }}
                            />
                            <label htmlFor="disable-anim">{t('disabled')}</label>
                        </p>{' '}
                    </div>
                </fieldset>
            </DropdownItem>
        </ModalDropdown>
    );
}

function renderLanguageDropdown(
    LanguageAndAccessibilityDropdownsProps: LanguageAndAccessibilityDropdownsProps
): JSX.Element {
    const { i18n, updateLocale } = LanguageAndAccessibilityDropdownsProps;
    return (
        <>
            {/* eslint-disable react/jsx-no-literals */}
            <Dropdown
                className="dropdown-lang is-right"
                color="light"
                aria-expanded="false"
                value=""
                onChange={(value: any): void => {
                    i18next.changeLanguage(value);
                    updateLocale(value);
                }}
            >
                <DropdownItem renderAs="a" value={i18n.language}>
                    {i18n.language}
                </DropdownItem>
                <DropdownItem renderAs="a" value="fr">
                    fr
                </DropdownItem>
                <DropdownItem renderAs="a" value="en">
                    en
                </DropdownItem>
                <DropdownItem renderAs="a" value="pt">
                    pt
                </DropdownItem>
            </Dropdown>
            {/* eslint-enable react/jsx-no-literals */}
        </>
    );
}

function renderContactLink(
    LanguageAndAccessibilityDropdownsProps: LanguageAndAccessibilityDropdownsProps
): JSX.Element {
    const { t } = LanguageAndAccessibilityDropdownsProps;
    return (
        <BrowserView viewClassName="LanguageAndAccessibilityDropdowns-Contact">
            <Link href="/contact" className="is-light button">
                <span>{t('common:contact')}</span>
            </Link>
        </BrowserView>
    );
}

function _LanguageAndAccessibilityDropdowns(props: LanguageAndAccessibilityDropdownsProps): JSX.Element {
    const { i18n, className, withContactButton } = props;

    useEffect((): void => {
        if (window && window.document) {
            document.documentElement.lang = i18n.language;
        }
    });

    return (
        <div className={classNames('LanguageAndAccessibilityDropdowns', className)}>
            {withContactButton && renderContactLink(props)}
            {renderAccessibilityDropdown(props)}
            {renderLanguageDropdown(props)}
        </div>
    );
}

/**
 * A sort of modal dropdown that remains opened
 * when an option is selected
 */
// as unknown as ComponentClass 'cos of shitty index.d.ts file in react-bulma-components
class ModalDropdown extends ((Dropdown as unknown) as ComponentClass<HTMLAttributes<HTMLElement>>) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    public select = (value: any): (() => void) => (): void => {
        if ((this.props as any).onChange) {
            (this.props as any).onChange(value);
        }
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

function mapStateToProps(state: AppState): StateProps {
    return state.UI;
}

const mapDispatchToProps: DispatchProps = {
    toggleAnimations: createToggleAnimationsAction,
    toggleContrasts: createToggleContrastsAction,
    updateLocale,
};

export const LanguageAndAccessibilityDropdowns = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('header')(_LanguageAndAccessibilityDropdowns));
